import axios, { AxiosInstance } from 'axios'
import handleError from '../utils/handleError'
import { store } from '../store'

export const API_URL = 'https://api.harvbrasil.com.br/'

const axiosInstance: AxiosInstance = axios.create({
	baseURL: `${API_URL}`,
	timeout: 300000
})

axiosInstance.interceptors.request.use(
	(request) => {
		if (process.env.NODE_ENV === 'development') {
			console.log('REQUEST', { url: request.url, data: request.data })
		}

		const accessToken: string = store().token.value.accessToken

		if (!request.headers) return

		if (accessToken) {
			request.headers.Authorization = `Bearer ${accessToken}`
		}

		return request
	},
	(err) => {
		console.log('REQUEST ERROR')
		handleError(err)

		throw err
	}
)

axiosInstance.interceptors.response.use(
	(response) => {
		if (process.env.NODE_ENV === 'development') {
			console.log(response.config.url, {
				data: response.data,
				status: response.status
			})
		}

		return response
	},
	(err) => {
		console.log('RESPONSE ERROR')
		handleError(err)

		throw err
	}
)

export default axiosInstance
